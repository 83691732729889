import { useMgoClient } from '@mgonetwork/dapp-kit';
import { type MgoClient } from '@mgonetwork/mango.js/client';
import { useQuery } from '@tanstack/react-query';

export function useGetAllEpochAddressMetrics(
	...input: Parameters<MgoClient['getAllEpochAddressMetrics']>
) {
	const client = useMgoClient();
	return useQuery({
		queryKey: ['get', 'all', 'epoch', 'addresses', ...input],
		queryFn: () => client.getAllEpochAddressMetrics(...input),
	});
}
