import { isMgoNSName, useMgoNSEnabled } from '@mgonetwork/core';
import { useLatestMgoSystemState, useMgoClient } from '@mgonetwork/dapp-kit';
import { type MgoClient, type MgoSystemStateSummary } from '@mgonetwork/mango.js/client';
import {
	isValidTransactionDigest,
	isValidMgoAddress,
	isValidMgoObjectId,
	normalizeMgoObjectId,
} from '@mgonetwork/mango.js/utils';
import { useQuery } from '@tanstack/react-query';

const isGenesisLibAddress = (value: string): boolean => /^(0x|0X)0{0,39}[12]$/.test(value);

type Results = { id: string; label: string; type: string }[];

const getResultsForTransaction = async (client: MgoClient, query: string) => {
	if (!isValidTransactionDigest(query)) return null;
	const txdata = await client.getTransactionBlock({ digest: query });
	return [
		{
			id: txdata.digest,
			label: txdata.digest,
			type: 'transaction',
		},
	];
};

const getResultsForObject = async (client: MgoClient, query: string) => {
	const normalized = normalizeMgoObjectId(query);
	if (!isValidMgoObjectId(normalized)) return null;

	const { data, error } = await client.getObject({ id: normalized });
	if (!data || error) return null;

	return [
		{
			id: data.objectId,
			label: data.objectId,
			type: 'object',
		},
	];
};

const getResultsForCheckpoint = async (client: MgoClient, query: string) => {
	// Checkpoint digests have the same format as transaction digests:
	if (!isValidTransactionDigest(query)) return null;

	const { digest } = await client.getCheckpoint({ id: query });
	if (!digest) return null;

	return [
		{
			id: digest,
			label: digest,
			type: 'checkpoint',
		},
	];
};

const getResultsForAddress = async (client: MgoClient, query: string, mgoNSEnabled: boolean) => {
	if (mgoNSEnabled && isMgoNSName(query)) {
		const resolved = await client.resolveNameServiceAddress({ name: query.toLowerCase() });
		if (!resolved) return null;
		return [
			{
				id: resolved,
				label: resolved,
				type: 'address',
			},
		];
	}

	const normalized = normalizeMgoObjectId(query);
	if (!isValidMgoAddress(normalized) || isGenesisLibAddress(normalized)) return null;

	const [from, to] = await Promise.all([
		client.queryTransactionBlocks({
			filter: { FromAddress: normalized },
			limit: 1,
		}),
		client.queryTransactionBlocks({
			filter: { ToAddress: normalized },
			limit: 1,
		}),
	]);

	if (!from.data?.length && !to.data?.length) return null;

	return [
		{
			id: normalized,
			label: normalized,
			type: 'address',
		},
	];
};

// Query for validator by pool id or mgo address.
const getResultsForValidatorByPoolIdOrMgoAddress = async (
	systemStateSummery: MgoSystemStateSummary | null,
	query: string,
) => {
	const normalized = normalizeMgoObjectId(query);
	if ((!isValidMgoAddress(normalized) && !isValidMgoObjectId(normalized)) || !systemStateSummery)
		return null;

	// find validator by pool id or mgo address
	const validator = systemStateSummery.activeValidators?.find(
		({ stakingPoolId, mgoAddress }) => stakingPoolId === normalized || mgoAddress === query,
	);

	if (!validator) return null;

	return [
		{
			id: validator.mgoAddress || validator.stakingPoolId,
			label: normalized,
			type: 'validator',
		},
	];
};

export function useSearch(query: string) {
	const client = useMgoClient();
	const { data: systemStateSummery } = useLatestMgoSystemState();
	const mgoNSEnabled = useMgoNSEnabled();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: ['search', query],
		queryFn: async () => {
			const results = (
				await Promise.allSettled([
					getResultsForTransaction(client, query),
					getResultsForCheckpoint(client, query),
					getResultsForAddress(client, query, mgoNSEnabled),
					getResultsForObject(client, query),
					getResultsForValidatorByPoolIdOrMgoAddress(systemStateSummery || null, query),
				])
			).filter((r) => r.status === 'fulfilled' && r.value) as PromiseFulfilledResult<Results>[];

			return results.map(({ value }) => value).flat();
		},
		enabled: !!query,
		cacheTime: 10000,
	});
}
