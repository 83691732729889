import { useCoinMetadata } from '@mgonetwork/core';
import { Mgo, Unstaked } from '@mgonetwork/icons';
import { type CoinMetadata } from '@mgonetwork/mango.js/client';
import clsx from 'clsx';

import { Image } from '~/ui/image/Image';

function CoinIcon({ coinMetadata }: { coinMetadata?: CoinMetadata | null }) {
	if (coinMetadata?.symbol === 'MGO') {
		return <Mgo className="h-2.5 w-2.5" />;
	}

	if (coinMetadata?.iconUrl) {
		return <Image rounded="full" alt={coinMetadata?.description} src={coinMetadata?.iconUrl} />;
	}

	return <Unstaked className="h-2.5 w-2.5" />;
}

export function Coin({ type }: { type: string }) {
	const { data: coinMetadata } = useCoinMetadata(type);
	const { symbol, iconUrl } = coinMetadata || {};

	return (
		<span
			className={clsx(
				'relative flex h-5 w-5 items-center justify-center rounded-xl text-white',
				(!coinMetadata || symbol !== 'MGO') &&
					'bg-gradient-to-r from-gradient-blue-start to-gradient-blue-end',
				symbol === 'MGO' && 'defined-bg-mgo',
				iconUrl && 'bg-gray-40',
			)}
		>
			<CoinIcon coinMetadata={coinMetadata} />
		</span>
	);
}

export interface CoinsStackProps {
	coinTypes: string[];
}

export function CoinsStack({ coinTypes }: CoinsStackProps) {
	return (
		<div className="flex">
			{coinTypes.map((coinType, index) => (
				<div key={index} className={clsx(index !== 0 && '-ml-1')}>
					<Coin type={coinType} />
				</div>
			))}
		</div>
	);
}
