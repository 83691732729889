import type { Infer } from 'superstruct';
import {
	array,
	boolean,
	define,
	is,
	literal,
	number,
	object,
	record,
	string,
	tuple,
	union,
} from 'superstruct';

export type MgoMoveFunctionArgTypesResponse = Infer<typeof MgoMoveFunctionArgType>[];

export const MgoMoveFunctionArgType = union([string(), object({ Object: string() })]);

export const MgoMoveFunctionArgTypes = array(MgoMoveFunctionArgType);
export type MgoMoveFunctionArgTypes = Infer<typeof MgoMoveFunctionArgTypes>;

export const MgoMoveModuleId = object({
	address: string(),
	name: string(),
});
export type MgoMoveModuleId = Infer<typeof MgoMoveModuleId>;

export const MgoMoveVisibility = union([literal('Private'), literal('Public'), literal('Friend')]);
export type MgoMoveVisibility = Infer<typeof MgoMoveVisibility>;

export const MgoMoveAbilitySet = object({
	abilities: array(string()),
});
export type MgoMoveAbilitySet = Infer<typeof MgoMoveAbilitySet>;

export const MgoMoveStructTypeParameter = object({
	constraints: MgoMoveAbilitySet,
	isPhantom: boolean(),
});
export type MgoMoveStructTypeParameter = Infer<typeof MgoMoveStructTypeParameter>;

export const MgoMoveNormalizedTypeParameterType = object({
	TypeParameter: number(),
});
export type MgoMoveNormalizedTypeParameterType = Infer<typeof MgoMoveNormalizedTypeParameterType>;

export type MgoMoveNormalizedType =
	| string
	| MgoMoveNormalizedTypeParameterType
	| { Reference: MgoMoveNormalizedType }
	| { MutableReference: MgoMoveNormalizedType }
	| { Vector: MgoMoveNormalizedType }
	| MgoMoveNormalizedStructType;

export const MoveCallMetric = tuple([
	object({
		module: string(),
		package: string(),
		function: string(),
	}),
	string(),
]);

export type MoveCallMetric = Infer<typeof MoveCallMetric>;

export const MoveCallMetrics = object({
	rank3Days: array(MoveCallMetric),
	rank7Days: array(MoveCallMetric),
	rank30Days: array(MoveCallMetric),
});

export type MoveCallMetrics = Infer<typeof MoveCallMetrics>;

function isMgoMoveNormalizedType(value: unknown): value is MgoMoveNormalizedType {
	if (!value) return false;
	if (typeof value === 'string') return true;
	if (is(value, MgoMoveNormalizedTypeParameterType)) return true;
	if (isMgoMoveNormalizedStructType(value)) return true;
	if (typeof value !== 'object') return false;

	const valueProperties = value as Record<string, unknown>;
	if (is(valueProperties.Reference, MgoMoveNormalizedType)) return true;
	if (is(valueProperties.MutableReference, MgoMoveNormalizedType)) return true;
	if (is(valueProperties.Vector, MgoMoveNormalizedType)) return true;
	return false;
}

export const MgoMoveNormalizedType = define<MgoMoveNormalizedType>(
	'MgoMoveNormalizedType',
	isMgoMoveNormalizedType,
);

export type MgoMoveNormalizedStructType = {
	Struct: {
		address: string;
		module: string;
		name: string;
		typeArguments: MgoMoveNormalizedType[];
	};
};

function isMgoMoveNormalizedStructType(value: unknown): value is MgoMoveNormalizedStructType {
	if (!value || typeof value !== 'object') return false;

	const valueProperties = value as Record<string, unknown>;
	if (!valueProperties.Struct || typeof valueProperties.Struct !== 'object') return false;

	const structProperties = valueProperties.Struct as Record<string, unknown>;
	if (
		typeof structProperties.address !== 'string' ||
		typeof structProperties.module !== 'string' ||
		typeof structProperties.name !== 'string' ||
		!Array.isArray(structProperties.typeArguments) ||
		!structProperties.typeArguments.every((value) => isMgoMoveNormalizedType(value))
	) {
		return false;
	}

	return true;
}

// NOTE: This type is recursive, so we need to manually implement it:
export const MgoMoveNormalizedStructType = define<MgoMoveNormalizedStructType>(
	'MgoMoveNormalizedStructType',
	isMgoMoveNormalizedStructType,
);

export const MgoMoveNormalizedFunction = object({
	visibility: MgoMoveVisibility,
	isEntry: boolean(),
	typeParameters: array(MgoMoveAbilitySet),
	parameters: array(MgoMoveNormalizedType),
	return: array(MgoMoveNormalizedType),
});
export type MgoMoveNormalizedFunction = Infer<typeof MgoMoveNormalizedFunction>;

export const MgoMoveNormalizedField = object({
	name: string(),
	type: MgoMoveNormalizedType,
});
export type MgoMoveNormalizedField = Infer<typeof MgoMoveNormalizedField>;

export const MgoMoveNormalizedStruct = object({
	abilities: MgoMoveAbilitySet,
	typeParameters: array(MgoMoveStructTypeParameter),
	fields: array(MgoMoveNormalizedField),
});
export type MgoMoveNormalizedStruct = Infer<typeof MgoMoveNormalizedStruct>;

export const MgoMoveNormalizedModule = object({
	fileFormatVersion: number(),
	address: string(),
	name: string(),
	friends: array(MgoMoveModuleId),
	structs: record(string(), MgoMoveNormalizedStruct),
	exposedFunctions: record(string(), MgoMoveNormalizedFunction),
});
export type MgoMoveNormalizedModule = Infer<typeof MgoMoveNormalizedModule>;

export const MgoMoveNormalizedModules = record(string(), MgoMoveNormalizedModule);
export type MgoMoveNormalizedModules = Infer<typeof MgoMoveNormalizedModules>;

export function extractMutableReference(
	normalizedType: MgoMoveNormalizedType,
): MgoMoveNormalizedType | undefined {
	return typeof normalizedType === 'object' && 'MutableReference' in normalizedType
		? normalizedType.MutableReference
		: undefined;
}

export function extractReference(
	normalizedType: MgoMoveNormalizedType,
): MgoMoveNormalizedType | undefined {
	return typeof normalizedType === 'object' && 'Reference' in normalizedType
		? normalizedType.Reference
		: undefined;
}

export function extractStructTag(
	normalizedType: MgoMoveNormalizedType,
): MgoMoveNormalizedStructType | undefined {
	if (typeof normalizedType === 'object' && 'Struct' in normalizedType) {
		return normalizedType;
	}

	const ref = extractReference(normalizedType);
	const mutRef = extractMutableReference(normalizedType);

	if (typeof ref === 'object' && 'Struct' in ref) {
		return ref;
	}

	if (typeof mutRef === 'object' && 'Struct' in mutRef) {
		return mutRef;
	}
	return undefined;
}
