// import { useMgoCoinData } from '@mgonetwork/core';
import { CoinLogo } from '@mgonetwork/icons';
import { Text } from '@mgonetwork/ui';

import { Card } from '~/ui/Card';
import { ButtonOrLink } from '~/ui/utils/ButtonOrLink';

export function MgoTokenCard() {
	// const { data } = useMgoCoinData();
	// const { currentPrice } = data || {};

	// const formattedPrice = currentPrice
	// 	? currentPrice.toLocaleString('en', {
	// 			style: 'currency',
	// 			currency: 'USD',
	// 	  })
	// 	: '--';
	const formattedPrice = '--';

	return (
		<ButtonOrLink target="_self" href="#">
			<Card growOnHover bg="definedBlack" spacing="lg" height="full">
				<div className="flex items-center gap-2">
					<div className="flex w-full flex-col gap-0.5">
						<CoinLogo style={{ marginBottom: '20px' }} />
						<Text variant="body/semibold" color="steel">
							1 MGO = {formattedPrice}
						</Text>
						<div style={{ marginBottom: '10px' }} />
						<Text variant="subtitleSmallExtra/medium" color="steel-darker">
							{/*via CoinGecko*/}
						</Text>
					</div>
				</div>
			</Card>
		</ButtonOrLink>
	);
}
