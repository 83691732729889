import React, { type ReactNode, useMemo, useState } from 'react';

import { ThemeContext } from '~/context';
import { Theme } from '~/utils/api/DefaultRpcClient';

export default function ThemeProvider({ children }: { children: ReactNode }): ReactNode {
	const [theme, setTheme] = useState<Theme | string>(Theme.Light);
	const localTheme = useMemo(() => {
		const storageTheme = localStorage.getItem('theme');
		if (storageTheme && storageTheme != 'undefined') {
			setTheme(storageTheme);
			return storageTheme;
		}
		return theme;
	}, [theme]);
	return (
		<ThemeContext.Provider
			value={[
				localTheme,
				(param: Theme | string) => {
					localStorage.setItem('theme', param);
					setTheme(param);
				},
			]}
		>
			<div className={localTheme}>{children}</div>
		</ThemeContext.Provider>
	);
}
