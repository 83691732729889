export function getFullnodeUrl(network: 'mainnet' | 'testnet' | 'devnet' | 'localnet') {
	switch (network) {
		case 'mainnet':
			return 'https://fullnode.mainnet.mangonetwork.io';
		case 'testnet':
			return 'https://fullnode.testnet.mangonetwork.io';
		case 'devnet':
			return 'https://fullnode.devnet.mangonetwork.io';
		case 'localnet':
			return 'http://127.0.0.1:9000';
		default:
			throw new Error(`Unknown network: ${network}`);
	}
}
