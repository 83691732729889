import { useMgoClient } from '@mgonetwork/dapp-kit';
import { useQuery } from '@tanstack/react-query';

export function useGetNetworkMetrics() {
	const client = useMgoClient();
	return useQuery({
		queryKey: ['home', 'metrics'],
		queryFn: () => client.getNetworkMetrics(),
		cacheTime: 24 * 60 * 60 * 1000,
		staleTime: Infinity,
		retry: 5,
	});
}
