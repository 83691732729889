import type { Infer } from 'superstruct';
import {
	any,
	array,
	assign,
	boolean,
	is,
	literal,
	nullable,
	number,
	object,
	optional,
	record,
	string,
	tuple,
	union,
	unknown,
} from 'superstruct';

import { ObjectOwner } from './common.js';
import type { OwnedObjectRef } from './transactions.js';

export const ObjectType = union([string(), literal('package')]);
export type ObjectType = Infer<typeof ObjectType>;

export const MgoObjectRef = object({
	/** Base64 string representing the object digest */
	digest: string(),
	/** Hex code as string representing the object id */
	objectId: string(),
	/** Object version */
	version: union([number(), string()]),
});
export type MgoObjectRef = Infer<typeof MgoObjectRef>;

export const MgoGasData = object({
	payment: array(MgoObjectRef),
	/** Gas Object's owner */
	owner: string(),
	price: string(),
	budget: string(),
});
export type MgoGasData = Infer<typeof MgoGasData>;

export const MgoObjectInfo = assign(
	MgoObjectRef,
	object({
		type: string(),
		owner: ObjectOwner,
		previousTransaction: string(),
	}),
);
export type MgoObjectInfo = Infer<typeof MgoObjectInfo>;

export const ObjectContentFields = record(string(), any());
export type ObjectContentFields = Infer<typeof ObjectContentFields>;

export const MovePackageContent = record(string(), unknown());
export type MovePackageContent = Infer<typeof MovePackageContent>;

export const MgoMoveObject = object({
	/** Move type (e.g., "0x2::coin::Coin<0x2::mgo::MGO>") */
	type: string(),
	/** Fields and values stored inside the Move object */
	fields: ObjectContentFields,
	hasPublicTransfer: boolean(),
});
export type MgoMoveObject = Infer<typeof MgoMoveObject>;

export const MgoMovePackage = object({
	/** A mapping from module name to disassembled Move bytecode */
	disassembled: MovePackageContent,
});
export type MgoMovePackage = Infer<typeof MgoMovePackage>;

export const MgoParsedData = union([
	assign(MgoMoveObject, object({ dataType: literal('moveObject') })),
	assign(MgoMovePackage, object({ dataType: literal('package') })),
]);
export type MgoParsedData = Infer<typeof MgoParsedData>;

export const MgoRawMoveObject = object({
	/** Move type (e.g., "0x2::coin::Coin<0x2::mgo::MGO>") */
	type: string(),
	hasPublicTransfer: boolean(),
	version: string(),
	bcsBytes: string(),
});
export type MgoRawMoveObject = Infer<typeof MgoRawMoveObject>;

export const MgoRawMovePackage = object({
	id: string(),
	/** A mapping from module name to Move bytecode enocded in base64*/
	moduleMap: record(string(), string()),
});
export type MgoRawMovePackage = Infer<typeof MgoRawMovePackage>;

// TODO(chris): consolidate MgoRawParsedData and MgoRawObject using generics
export const MgoRawData = union([
	assign(MgoRawMoveObject, object({ dataType: literal('moveObject') })),
	assign(MgoRawMovePackage, object({ dataType: literal('package') })),
]);
export type MgoRawData = Infer<typeof MgoRawData>;

export const MGO_DECIMALS = 9;

export const MIST_PER_MGO = BigInt(1000000000);

/** @deprecated Use `string` instead. */
export const ObjectDigest = string();
/** @deprecated Use `string` instead. */
export type ObjectDigest = Infer<typeof ObjectDigest>;

export const MgoObjectResponseError = object({
	code: string(),
	error: optional(string()),
	object_id: optional(string()),
	parent_object_id: optional(string()),
	version: optional(string()),
	digest: optional(string()),
});
export type MgoObjectResponseError = Infer<typeof MgoObjectResponseError>;
export const DisplayFieldsResponse = object({
	data: nullable(optional(record(string(), string()))),
	error: nullable(optional(MgoObjectResponseError)),
});
export type DisplayFieldsResponse = Infer<typeof DisplayFieldsResponse>;
// TODO: remove after all envs support the new DisplayFieldsResponse;
export const DisplayFieldsBackwardCompatibleResponse = union([
	DisplayFieldsResponse,
	optional(record(string(), string())),
]);
export type DisplayFieldsBackwardCompatibleResponse = Infer<
	typeof DisplayFieldsBackwardCompatibleResponse
>;

export const MgoObjectData = object({
	objectId: string(),
	version: string(),
	digest: string(),
	/**
	 * Type of the object, default to be undefined unless MgoObjectDataOptions.showType is set to true
	 */
	type: nullable(optional(string())),
	/**
	 * Move object content or package content, default to be undefined unless MgoObjectDataOptions.showContent is set to true
	 */
	content: nullable(optional(MgoParsedData)),
	/**
	 * Move object content or package content in BCS bytes, default to be undefined unless MgoObjectDataOptions.showBcs is set to true
	 */
	bcs: nullable(optional(MgoRawData)),
	/**
	 * The owner of this object. Default to be undefined unless MgoObjectDataOptions.showOwner is set to true
	 */
	owner: nullable(optional(ObjectOwner)),
	/**
	 * The digest of the transaction that created or last mutated this object.
	 * Default to be undefined unless MgoObjectDataOptions.showPreviousTransaction is set to true
	 */
	previousTransaction: nullable(optional(string())),
	/**
	 * The amount of MGO we would rebate if this object gets deleted.
	 * This number is re-calculated each time the object is mutated based on
	 * the present storage gas price.
	 * Default to be undefined unless MgoObjectDataOptions.showStorageRebate is set to true
	 */
	storageRebate: nullable(optional(string())),
	/**
	 * Display metadata for this object, default to be undefined unless MgoObjectDataOptions.showDisplay is set to true
	 * This can also be None if the struct type does not have Display defined
	 * See more details in https://forums.mgo.io/t/nft-object-display-proposal/4872
	 */
	display: nullable(optional(DisplayFieldsBackwardCompatibleResponse)),
});
export type MgoObjectData = Infer<typeof MgoObjectData>;

/**
 * Config for fetching object data
 */
export const MgoObjectDataOptions = object({
	/* Whether to fetch the object type, default to be true */
	showType: nullable(optional(boolean())),
	/* Whether to fetch the object content, default to be false */
	showContent: nullable(optional(boolean())),
	/* Whether to fetch the object content in BCS bytes, default to be false */
	showBcs: nullable(optional(boolean())),
	/* Whether to fetch the object owner, default to be false */
	showOwner: nullable(optional(boolean())),
	/* Whether to fetch the previous transaction digest, default to be false */
	showPreviousTransaction: nullable(optional(boolean())),
	/* Whether to fetch the storage rebate, default to be false */
	showStorageRebate: nullable(optional(boolean())),
	/* Whether to fetch the display metadata, default to be false */
	showDisplay: nullable(optional(boolean())),
});
export type MgoObjectDataOptions = Infer<typeof MgoObjectDataOptions>;

export const ObjectStatus = union([literal('Exists'), literal('notExists'), literal('Deleted')]);
export type ObjectStatus = Infer<typeof ObjectStatus>;

export const GetOwnedObjectsResponse = array(MgoObjectInfo);
export type GetOwnedObjectsResponse = Infer<typeof GetOwnedObjectsResponse>;

export const MgoObjectResponse = object({
	data: nullable(optional(MgoObjectData)),
	error: nullable(optional(MgoObjectResponseError)),
});
export type MgoObjectResponse = Infer<typeof MgoObjectResponse>;

export type Order = 'ascending' | 'descending';

/* -------------------------------------------------------------------------- */
/*                              Helper functions                              */
/* -------------------------------------------------------------------------- */

/* -------------------------- MgoObjectResponse ------------------------- */

export function getMgoObjectData(resp: MgoObjectResponse): MgoObjectData | null | undefined {
	return resp.data;
}

export function getObjectDeletedResponse(resp: MgoObjectResponse): MgoObjectRef | undefined {
	if (
		resp.error &&
		'object_id' in resp.error &&
		'version' in resp.error &&
		'digest' in resp.error
	) {
		const error = resp.error as MgoObjectResponseError;
		return {
			objectId: error.object_id,
			version: error.version,
			digest: error.digest,
		} as MgoObjectRef;
	}

	return undefined;
}

export function getObjectNotExistsResponse(resp: MgoObjectResponse): string | undefined {
	if (
		resp.error &&
		'object_id' in resp.error &&
		!('version' in resp.error) &&
		!('digest' in resp.error)
	) {
		return (resp.error as MgoObjectResponseError).object_id as string;
	}

	return undefined;
}

export function getObjectReference(
	resp: MgoObjectResponse | OwnedObjectRef,
): MgoObjectRef | undefined {
	if ('reference' in resp) {
		return resp.reference;
	}
	const exists = getMgoObjectData(resp);
	if (exists) {
		return {
			objectId: exists.objectId,
			version: exists.version,
			digest: exists.digest,
		};
	}
	return getObjectDeletedResponse(resp);
}

/* ------------------------------ MgoObjectRef ------------------------------ */

export function getObjectId(data: MgoObjectResponse | MgoObjectRef | OwnedObjectRef): string {
	if ('objectId' in data) {
		return data.objectId;
	}
	return (
		getObjectReference(data)?.objectId ?? getObjectNotExistsResponse(data as MgoObjectResponse)!
	);
}

export function getObjectVersion(
	data: MgoObjectResponse | MgoObjectRef | MgoObjectData,
): string | number | undefined {
	if ('version' in data) {
		return data.version;
	}
	return getObjectReference(data)?.version;
}

/* -------------------------------- MgoObject ------------------------------- */

export function isMgoObjectResponse(
	resp: MgoObjectResponse | MgoObjectData,
): resp is MgoObjectResponse {
	return (resp as MgoObjectResponse).data !== undefined;
}

/**
 * Deriving the object type from the object response
 * @returns 'package' if the object is a package, move object type(e.g., 0x2::coin::Coin<0x2::mgo::MGO>)
 * if the object is a move object
 */
export function getObjectType(
	resp: MgoObjectResponse | MgoObjectData,
): ObjectType | null | undefined {
	const data = isMgoObjectResponse(resp) ? resp.data : resp;

	if (!data?.type && 'data' in resp) {
		if (data?.content?.dataType === 'package') {
			return 'package';
		}
		return getMoveObjectType(resp);
	}
	return data?.type;
}

export function getObjectPreviousTransactionDigest(
	resp: MgoObjectResponse,
): string | null | undefined {
	return getMgoObjectData(resp)?.previousTransaction;
}

export function getObjectOwner(
	resp: MgoObjectResponse | ObjectOwner,
): ObjectOwner | null | undefined {
	if (is(resp, ObjectOwner)) {
		return resp;
	}
	return getMgoObjectData(resp)?.owner;
}

export function getObjectDisplay(resp: MgoObjectResponse): DisplayFieldsResponse {
	const display = getMgoObjectData(resp)?.display;
	if (!display) {
		return { data: null, error: null };
	}
	if (is(display, DisplayFieldsResponse)) {
		return display;
	}
	return {
		data: display,
		error: null,
	};
}

export function getSharedObjectInitialVersion(
	resp: MgoObjectResponse | ObjectOwner,
): string | null | undefined {
	const owner = getObjectOwner(resp);
	if (owner && typeof owner === 'object' && 'Shared' in owner) {
		return owner.Shared.initial_shared_version;
	} else {
		return undefined;
	}
}

export function isSharedObject(resp: MgoObjectResponse | ObjectOwner): boolean {
	const owner = getObjectOwner(resp);
	return !!owner && typeof owner === 'object' && 'Shared' in owner;
}

export function isImmutableObject(resp: MgoObjectResponse | ObjectOwner): boolean {
	const owner = getObjectOwner(resp);
	return owner === 'Immutable';
}

export function getMoveObjectType(resp: MgoObjectResponse): string | undefined {
	return getMoveObject(resp)?.type;
}

export function getObjectFields(
	resp: MgoObjectResponse | MgoMoveObject | MgoObjectData,
): ObjectContentFields | undefined {
	if ('fields' in resp) {
		return resp.fields;
	}
	return getMoveObject(resp)?.fields;
}

export interface MgoObjectDataWithContent extends MgoObjectData {
	content: MgoParsedData;
}

function isMgoObjectDataWithContent(data: MgoObjectData): data is MgoObjectDataWithContent {
	return data.content !== undefined;
}

export function getMoveObject(data: MgoObjectResponse | MgoObjectData): MgoMoveObject | undefined {
	const mgoObject = 'data' in data ? getMgoObjectData(data) : (data as MgoObjectData);

	if (
		!mgoObject ||
		!isMgoObjectDataWithContent(mgoObject) ||
		mgoObject.content.dataType !== 'moveObject'
	) {
		return undefined;
	}

	return mgoObject.content as MgoMoveObject;
}

export function hasPublicTransfer(data: MgoObjectResponse | MgoObjectData): boolean {
	return getMoveObject(data)?.hasPublicTransfer ?? false;
}

export function getMovePackageContent(
	data: MgoObjectResponse | MgoMovePackage,
): MovePackageContent | undefined {
	if ('disassembled' in data) {
		return data.disassembled;
	}
	const mgoObject = getMgoObjectData(data);
	if (mgoObject?.content?.dataType !== 'package') {
		return undefined;
	}
	return (mgoObject.content as MgoMovePackage).disassembled;
}

export const CheckpointedObjectId = object({
	objectId: string(),
	atCheckpoint: optional(number()),
});
export type CheckpointedObjectId = Infer<typeof CheckpointedObjectId>;

export const PaginatedObjectsResponse = object({
	data: array(MgoObjectResponse),
	nextCursor: optional(nullable(string())),
	hasNextPage: boolean(),
});
export type PaginatedObjectsResponse = Infer<typeof PaginatedObjectsResponse>;

// mirrors mgo_json_rpc_types:: MgoObjectDataFilter
export type MgoObjectDataFilter =
	| { MatchAll: MgoObjectDataFilter[] }
	| { MatchAny: MgoObjectDataFilter[] }
	| { MatchNone: MgoObjectDataFilter[] }
	| { Package: string }
	| { MoveModule: { package: string; module: string } }
	| { StructType: string }
	| { AddressOwner: string }
	| { ObjectOwner: string }
	| { ObjectId: string }
	| { ObjectIds: string[] }
	| { Version: string };

export type MgoObjectResponseQuery = {
	filter?: MgoObjectDataFilter;
	options?: MgoObjectDataOptions;
};

export const ObjectRead = union([
	object({
		details: MgoObjectData,
		status: literal('VersionFound'),
	}),
	object({
		details: string(),
		status: literal('ObjectNotExists'),
	}),
	object({
		details: MgoObjectRef,
		status: literal('ObjectDeleted'),
	}),
	object({
		details: tuple([string(), number()]),
		status: literal('VersionNotFound'),
	}),
	object({
		details: object({
			asked_version: number(),
			latest_version: number(),
			object_id: string(),
		}),
		status: literal('VersionTooHigh'),
	}),
]);
export type ObjectRead = Infer<typeof ObjectRead>;
