import { type MgoEvent } from '@mgonetwork/mango.js/client';

export function getValidatorMoveEvent(validatorsEvent: MgoEvent[], validatorAddress: string) {
	const event = validatorsEvent.find(
		({ parsedJson }) =>
			(parsedJson as { validator_address?: unknown })!.validator_address === validatorAddress,
	);

	return event && event.parsedJson;
}
